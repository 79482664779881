import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Features from '../components/Features'

export const ServicesPageTemplate = ({
         image,
         title,
         heading,
         description,
         intro
       }) => (
    <section className="sub">
           <div className="container">
             <div className="columns">
          <div className="column is-10 is-offset-1 margin-bottom">
                 <div className="content">
                   <div
                     className="full-width-image-container margin-top-0"
                     style={{
                       backgroundImage: `url(${
                         !!image.childImageSharp
                           ? image.childImageSharp.fluid.src
                           : image
                       })`
                     }}
                   >
                     <h2
                       className="has-text-weight-bold is-size-1"
                       style={{
                         color: "white",
                         padding: "1rem"
                       }}
                     >
                       {title}
                     </h2>
                   </div>
              <div className="columns services-header">
                     <div className="column">
                       <h3 className="has-text-weight-semibold is-size-2">
                         {heading}
                       </h3>
                       <p>{description}</p>
                     </div>
                   </div>
              <div className="services">
                   <Features gridItems={intro.blurbs} />
              </div>
                 </div>
               </div>
             </div>
           </div>
         </section>
       );

ServicesPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  heading: PropTypes.string,
  description: PropTypes.string,
  intro: PropTypes.shape({
    blurbs: PropTypes.array,
  })
}

const ServicesPage = ({ pageContext: { locale }, ...props }) => {
  const { node: data } = props.data.ServicesPageData.edges[0]

  return (
    <Layout locale={data.frontmatter.locale}>
      <ServicesPageTemplate
        image={data.frontmatter.image}
        title={data.frontmatter.title}
        heading={data.frontmatter.heading}
        description={data.frontmatter.description}
        intro={data.frontmatter.intro}
      />
    </Layout>
  )
}

ServicesPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default ServicesPage

export const servicesPageQuery = graphql`
query servicesPageQuery($locale: String) {
  ServicesPageData: allMarkdownRemark(
    filter: {frontmatter: { templateKey: {eq: "services-page"}, locale: { eq: $locale }}}
  ) {
    edges {
      node {
        fields {
          slug
        }
        html
        frontmatter {
          title
          locale
          image {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          heading
          description
          intro {
            blurbs {
              image {
                childImageSharp {
                  fluid(maxWidth: 240, quality: 64) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              header
              text
            }
            heading
            description
          }
        }
      }
    }
  }
}
`


